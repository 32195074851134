import React from "react"
//import { StaticQuery, graphql, Link } from "gatsby"
//import Image from "gatsby-image"
import Image from "./image";

//import { rhythm } from "../utils/typography"

function Thumb( {bucket, filename} ) {
  return (
    <h3><Image bucket={bucket} filename={filename} /></h3>
  )
}

export default Thumb
