import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
//import Image from "gatsby-image"


import ExternalApiComponent from "../../services/imageService";
import Thumb from "./thumb";
import Image from "./image";

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'


function Media( {data} ) {
  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  const getURLs = async (data) => {
    const src = data.flatMap((media) => {
      console.log(media);
      if (media.file){
        return [media.file.path_thumb, media.file.path];
      }
      else {
        return ['', '']
      }
    });

    //console.log(src);
    
    const token = await getAccessTokenSilently({
      audience: `https://dev.junk.rocks`,
      scope: ["read:images",]
    });
    try{
      if(!data){
        throw "No data";
      }
      if(!data[0].file){
        throw "First media has no File";
      }

      const response = await fetch(`/api/images`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({src:src, bucket: data[0].file.bucket})
      });

      const responseData = await response.json();
      console.log(responseData.urls);

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData.urls,
      });    
    }
    catch(error){
      console.log(error);
      setState({
        ...state,
        error: error.error,
      });
    }
  }

  useEffect(() => {
    getURLs( data );
    console.log(
      "This only happens ONCE.  But it happens AFTER the initial render."
    );
  }, []);

  const gallery = (
    <Gallery>
        {data.map(( node ) => {
          return ( node.file &&
            <Item
                original={state.apiMessage[node.file.path]}
                thumbnail={state.apiMessage[node.file.path_thumb]}
                width={node.width}
                height={node.height}
            >
            {({ ref, open }) => (
              //<Image ref={ref} onClick={open} bucket={node.file.bucket} filename={node.file.path_thumb} />
              <img ref={ref} onClick={open} src={state.apiMessage[node.file.path_thumb]} />
            )}
            </Item>
          )
        })}
    </Gallery>
    );

  return (
     state.apiMessage ? gallery : <h3>Loading!</h3>
  );


}

export default Media

/*
                <Thumb bucket={ node.file.bucket } filename={node.file.path_thumb} />
                <p class="thumb-caption">height: {node.height}</p>
                <p class="thumb-caption">width: {node.width}</p>
  */