import React from "react"
import { Link, graphql } from "gatsby"
import Moment from 'react-moment';
import moment from 'moment';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"

import Media from "../components/gallery/media"

import { rhythm, scale } from "../utils/typography"
import Profile from "../components/auth/profile"

import { withAuthenticationRequired } from '@auth0/auth0-react';



class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mysqlPost
    const media = post.media || []
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    if(post == null){
        return ( <p> post is null </p> )
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={moment(post.created).format("LLLL")}
        />
        <h1>
          <Moment format="LLLL">{post.created}</Moment><br/>
        </h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
        </p>
        <div dangerouslySetInnerHTML={{ __html: post.content || "or nothing" }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />

        <div>

        <Media data={ media }/>

        </div>

        <p/>
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={"/"+ previous.mysqlId} rel="prev">
                ← <Moment format="LLL">{previous.created}</Moment>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={"/"+ next.mysqlId} rel="next">
                <Moment format="LLL">{next.created}</Moment> →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default withAuthenticationRequired(BlogPostTemplate, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});


export const pageQuery = graphql`
  query BlogPostBySlug($slug: Int!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mysqlPost(mysqlId: { eq: $slug }) {
        content
        mysqlId
        created
        created_utc
        author
        community_id
        media {
          filename
          contentType
          height
          width
          file {
            bucket
            path
            path_thumb
            size
          }
        }
    }
  }
`